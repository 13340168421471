export default function convertStatus(status: string) {
  switch (status) {
    case "before_delivery":
      return '受入前';
    case "delivered":
      return '使用可能';
    case "in_use":
      return '使用中';
    case "return":
      return '返却済';
    case "invalid_data":
      return '不正なステータスを検知';
    default:
      return '不正なステータスを検知'
  }
}