import React, { useEffect, useState } from "react";

import PageContent from "../../../components/pageContent";
import CustomButton from "../../../components/custom-button";
import CommonModal from "../../../components/common-modal";
import ErrorSmallIcon from "../../../components/icons/error-small-icon";

import {
  TextField,
  View,
  Flex,
  Badge,
  Divider,
  SelectField,
  Text,
  Loader
} from "@aws-amplify/ui-react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SelectIcon from "../../../components/icons/select-icon";

import { IEqEnd } from "../../../types/IEq";
import { EqApi } from "../../../api/eq";
import { UserApi } from "../../../api/user";
import { CompanyApi } from "../../../api/company";
import { SiteApi } from "../../../api/site";
// utils
import formatDate from "../../../utils/formatDate";
import convertStatus from "../../../utils/geStatus";
import getBadgeColor from "../../../utils/getBadgeColor";

const defaultButtonProps = {
  text: '',
  type: 'primary',
  iconPosition: '',
  iconName: '',
  size: '',
  disabled: false,
  click: () => { return '' }
}

export default function EqUseEndPage() {
  const api = EqApi();
  const userApi = UserApi();
  const companyApi = CompanyApi();
  const siteApi = SiteApi()
  const location = useLocation();
  const { state } = location;
  const pageTitle = state?.pageTitle || ''
  const username = localStorage.getItem('username') ?? ''
  const navigate = useNavigate();
  const { equipmentId } = useParams()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [disableButton, setDisableButton] = useState(false)
  const [errorMessage, setErrorMessage] = useState([] as any)
  const [hasFormError, setHasFormError] = useState(false)
  const [eqName, setEqName] = useState('')
  const [category, setCategory] = useState('')
  const [status, setStatus] = useState('')
  const [companyList, setCompanyList] = useState([] as any)
  const [companyName, setCompanyName] = useState([] as any)
  const [locationList, setLocationList] = useState([] as any)
  const defaultDate = new Date()
  const [payload, setPayload] = useState<IEqEnd>({
    schedule_id: -1,
    delivery_schedule: '',
    return_schedule: '',
    actual_delivery: '',
    actual_return: '',
    actual_company_id: '',
    actual_company_name: '',
    actual_user_id: '',
    actual_user_name: '',
    actual_use_location: '',
    actual_return_register_user_id: username,
    actual_return_register_user_name: username,//not using
  })

  const breadCrumbsItems = [
    { href: '/', label: 'ホーム' },
    { href: '/eq/list', label: '機材一覧' },
    { href: `/eq/${equipmentId}`, label: '機材詳細' },
    { href: '', label: '使用終了登録' },
  ]

  // const formatDate = (dateString: string): string => {
  //   const date = new Date(dateString);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const day = String(date.getDate()).padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // }

  const openModal = () => {
    setIsModalOpen(true)
  };

  const handleChange = (item:any, value: any) => {
    setPayload({ ...payload, [item]: value })
  };

  const handleSubmit = async () => {
    if(payload.schedule_id<0){
      setErrorMessage(['データ取得エラーが発生しました。再度ページを更新してから登録してください']);
      return;
    }
    setDisableButton(true)
    setErrorMessage([])
    setHasFormError(false)
    const createPayload : any= {
      schedule_id: payload.schedule_id,
      actual_return: payload.actual_return + ' 00:00:00',
      actual_use_location: payload.actual_use_location || locationList[0]?.name,
      equipment_id: equipmentId,
      actual_return_register_user_id: payload.actual_return_register_user_id
    }
    if(!createPayload.actual_use_location){
      setErrorMessage(['工区を選択してください。選択できない場合には現場管理者にお問い合わせください'])
    }
    if((new Date(payload.actual_return)< new Date(payload.return_schedule) )){
      createPayload['return_schedule'] = payload.actual_return + ' 00:00:00';
      createPayload['delivery_schedule'] = payload.delivery_schedule;
    }

    try {
      // @ts-ignore
      const { data } = await api.dateTimeEdit(equipmentId, createPayload)
      if(data?.success) {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasFormError(false)
        setErrorMessage('')
        // navigate(`/eq/schedule/${ equipmentId }`)
        openModal()
      } else {
        setDisableButton(false)
        setIsModalOpen(false)
        setHasFormError(true)

        const errors = data?.error?.errors
        const toArray = Object.keys(errors).map((key) => [key, errors[key]]);
        setErrorMessage([...toArray])
      }
    } catch(err) {
      console.log(err)
    }
  };

  const showCancelModal = () => {
    setIsCancelModal(!isCancelModal)
  }

  const handleCancel = () => {
    if (document.querySelector(".main-body")?.classList.contains('hidden')) {
      document.querySelector(".main-body")?.classList.remove('hidden');
    }
    setIsModalOpen(false);
    navigate(`/eq/schedule/${ equipmentId }`)
  };
  const getFormattedTodayDate = ()  =>{
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const getDetail = async () => {
    setLoader(true);
    // @ts-ignore
    const { data } = await api.getDetail(equipmentId);
    if (data?.success) {
      setLoader(false);
      setFetchError(false);
      setCategory(data?.data?.equipment_category1_name)
      // setStatus(data?.data?.status)
      setEqName(data?.data?.equipment_name)
      getLocationList(data?.data?.site_id)

      const convert = defaultDate.toLocaleDateString('ja-JP')
      const format = convert?.split('/')
      const checkMonth = format[1].length === 1 ? '0' + format[1] : format[1]
      const checkDate = format[2].length === 1 ? '0' + format[2] : format[2]

      const userDetail = await userApi.getDetail(Number(username));
      if (userDetail?.data?.success) {
        setPayload({
          schedule_id: data?.data?.equipment_manage_delivery_schedule_id,
          delivery_schedule:data?.data?.use_delivery_schedule?.split(' ')[0] ?? format[0] + '-' + checkMonth + '-' + checkDate,
          return_schedule:data?.data?.use_return_schedule?.split(' ')[0] ?? format[0] + '-' + checkMonth + '-' + checkDate,
          actual_delivery:data?.data?.actual_delivery?.split(' ')[0] ?? format[0] + '-' + checkMonth + '-' + checkDate,
          actual_return: getFormattedTodayDate(),
          actual_use_location: data?.data?.actual_use_location,
          actual_company_id: data?.data?.actual_company_id ?? username.slice(0,3),
          actual_company_name: data?.data?.actual_company_name ?? username.slice(0,3),
          actual_user_id: data?.data?.actual_user_id ?? username,
          actual_user_name: data?.data?.actual_user_name ?? username,
          actual_return_register_user_id: username,
          actual_return_register_user_name: username,//not using
        });
        setCompanyName(userDetail?.data?.company_name)
      } else {
        setPayload({
          ...payload,
          actual_delivery: format[0] + '-' + checkMonth + '-' + checkDate,
        });
      }
    } else {
      setFetchError(true);
    }
  };

  const getCompanyList = async () => {
    // setLoader(true);
    const { data, status } = await companyApi.getList();
    if (status === 200) {
      setCompanyList([...data?.data])
    }
  };

  const getLocationList = async (id: any) => {
    // setLoader(true);
    const { data } = await siteApi.getDetail(id);
    try {
      if (data?.success) {
        setLocationList([...data?.data?.site_construction_locations])
      } else {
        setLocationList([])
      }
    } catch(err) {
      return err
    }
  };

  const getStatus = async () => {
    const { data } = await api.getEqStatus(equipmentId ?? '')
    try {
      if(data?.success) {
        const stat = convertStatus(data?.data?.status)
        setStatus(stat)
      } else {
        const stat = convertStatus('in_use')
        setStatus(stat)
      }
    } catch(err) {
      return err
    }   
  }

  useEffect(() => {
    getDetail()
    getCompanyList()
    getStatus()
  // eslint-disable-next-line
  }, [])

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={pageTitle}
    >
      <View className="main-content">
        <View
          as='form'
          onSubmit={handleSubmit}
          className='custom-form'
        >
          <View>
            <Flex wrap="wrap">
              <View
                border={`1px solid #757575`}
                padding={"5px 20px"}
                width={"fit"}
                fontWeight={500}
                fontSize={14}
                lineHeight="20px"
                letterSpacing="0.17px"
              >
                { category }
              </View>
              <View
                fontWeight={700}
                fontSize={24}
                lineHeight="29px"
              >
                { eqName }
              </View>
              <View
                fontWeight={700}
                fontSize={24}
                lineHeight="29px"
              >
                <Badge
                  backgroundColor={getBadgeColor(status).bg}
                  color={getBadgeColor(status).color}
                >
                  { status }
                </Badge>
              </View>
            </Flex>
          </View>
          <Divider orientation="horizontal"  marginBottom={20} marginTop={15} />
          { hasFormError ? 
            (
              <Flex className="signin-error-message">
                <View className="error-icon">
                  <ErrorSmallIcon fill='#D32F2F' />
                </View>
                <View>
                  {
                    errorMessage.map((item: any, index: number) => {
                      return (
                        <View key={index} display=''>
                          { item[0].replaceAll('_', ' ') + ' ' + item[1] }
                        </View>
                      )
                    })
                  }
                </View>
              </Flex>
            ) : null
          }
          {!loader ? (
            <>
              <Flex>
                <TextField
                  label="使用開始日（実績）"
                  width={"50%"}
                  type="date"
                  value={payload.actual_delivery}
                  disabled
                  //onChange={(event) => handleChange('actual_delivery', event.target.value)}
                />
                <TextField
                  label="使用終了日（実績）"
                  width={"50%"}
                  type="date"
                  value={payload.actual_return}
                  disabled
                  //onChange={(event) => handleChange('actual_return', event.target.value)}
                />
              </Flex>
              <SelectField
                label="使用業者（実績）"
                disabled
                value={ payload.actual_company_id }
                icon={<SelectIcon />}
                >
                <option value={payload.actual_company_id} key={payload.actual_company_id}>{ payload.actual_company_name }</option>
                { /*If disabled will be removed, you have to implement.
                  companyList?.map((item: any, index: any) => {
                    return (
                      <option value={item?.company_id} key={index}>{ item?.company_name }</option>
                    )
                  })
                */}
                </SelectField>
              <TextField
                label="使用担当者（実績）"
                value={ payload.actual_user_name }
                disabled
              />
              <SelectField
                label="使用場所（実績）"
                value={ payload.actual_use_location}
                onChange={(event) => handleChange('actual_use_location', event.target.value)}
                icon={<SelectIcon />}
              >
              {
                locationList?.map((item: any, index: any) => {
                  return (
                    <option value={item?.name} key={index}>{ item?.name }</option>
                  )
                })
              }
              </SelectField>
              <Flex
                  justifyContent="flex-end"
                  marginTop="1rem"
                  alignItems={`center`}
                  direction={{
                    base: 'column',
                    medium: 'row'
                }}
              >
                <CustomButton
                  {...defaultButtonProps}
                  text='機材使用終了'
                  size='medium'
                  width='medium'
                  borderRadius='large'
                  iconName={ disableButton ? 'loader' : '' }
                  iconPosition='right'
                  disabled={ disableButton }
                  click={() => handleSubmit()}
                />
                <CustomButton
                  {...defaultButtonProps}
                  text='キャンセル'
                  type='bordered-transparent'
                  click={showCancelModal}
                />
              </Flex>
              <CommonModal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  icon="icon"
                  textHeading="機材使用を確定しました"
                  textsmall={`機材の使用状況を「使用可能」に更新しました。`}
                  periodUse={`実績使用期間: ${ payload.actual_delivery } - ${ payload.actual_return }`}
                  //user={`確認者: ${ companyName || '-' }`}
                  //users={`使用終了登録者： ${  || '-' }`}
                  //location={`使用場所（実績）: ${ payload.actual_use_location || '-' }`}
                >
                  <View margin={`0 auto`}>
                    <CustomButton
                      { ...defaultButtonProps }
                      text='機材情報を確認'
                      size='medium'
                      width='medium'
                      borderRadius='large'
                      click={() => navigate(`/eq/schedule/${ equipmentId }`)}
                    />
                  </View>
              </CommonModal>
              {/* cancel modal */}
              <CommonModal
                isOpen={isCancelModal}
                textHeading="入力内容を破棄してスケジュールに
                戻りますか？"
              >
                <Flex justifyContent="center" margin={`0 auto`}>
                  <CustomButton
                    { ...defaultButtonProps }
                    text='編集へ戻る'
                    type='bordered-transparent'
                    click={showCancelModal}
                  />
                  <CustomButton
                    { ...defaultButtonProps }
                    text='破棄'
                    size='xs'
                    width='small'
                    click={handleCancel}
                  />
                </Flex>
              </CommonModal>
            </>
          ) : (
            <View className="table-loader">
              <Loader width="5rem" height="5rem" filledColor="#2196F3" />
            </View>
          )}
          {fetchError && (
            <Text className="error-fetch-message">
              Error in fetching data. Please contact administrator.
            </Text>
          )}
        </View>
      </View>
    </PageContent>
  );
}
