import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigationType, NavigationType } from "react-router-dom";
// import { useLocation, useParams } from "react-router-dom";
import { EqApi } from "../../api/eq";
import { AuthorityContext } from "../../App";

// custom comppage: numberonents
import PageContent from "../../components/pageContent";
import CustomButton from "../../components/custom-button";
import CommonModal from "../../components/common-modal";
import ErrorSmallIcon from "../../components/icons/error-small-icon";

// types
import { IEq, IEqCategoryList } from "../../types/IEq";

// amplify ui
import {
  Flex,
  View,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Pagination,
  SelectField,
  IconsProvider,
  SearchField,
  Badge,
  Loader,
  Text,
  TextField,
  TableHead,
} from "@aws-amplify/ui-react";

// icons
import SelectIcon from "../../components/icons/select-icon";
import ArrowLeftIcon from "../../components/icons/arrow-left";
import ArrowRightIcon from "../../components/icons/arrow-right";
import DateIcon from "../../components/icons/date-icon";

// utils
import formatDate from "../../utils/formatDate";
import convertStatus from "../../utils/geStatus";
import getDeviceType from "../../utils/getDeviceType";
import getBadgeColor from "../../utils/getBadgeColor";

// api
import { CompanyApi } from "../../api/company";
import { SiteApi } from "../../api/site";

type SiteSelect = {
  value: string;
  label: string;
};

export default function EqListPage() {
  const [isMobile, setIsMobile] = useState(getDeviceType() === "mobile");
  const { company, settings, site }: any = useContext(AuthorityContext);
  const pageTitle = localStorage.getItem("currentPage") ?? "";
  const api = EqApi();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [dataList, setDataList] = useState([] as any);
  const itemsLimit = 20;
  const [loader, setLoader] = useState(true);
  const [fetchError, setFetchError] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [idSelected, setIdSelected] = useState("");
  const [nameSelected, setNameSelected] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchPayload, setSearchPayload] = useState("");
  const [lastSearchPayload, setlastSearchPayload] = useState({} as any);
  const [indexOfLastUser, setIndexOfLastUser] = useState<number>(0)
  const [indexOfFirstUser, setIndexOfFirstUser] = useState<number>(0)
  // const indexOfLastUser = currentPageIndex * itemsLimit;
  // const indexOfFirstUser = indexOfLastUser - itemsLimit + 1;
  // const currentItems = dataList.slice(indexOfFirstUser, indexOfLastUser);
  const [siteSelections, setSiteSelections] = useState<
    SiteSelect[] | undefined
  >([]);
  const initialSelectedSiteId: string =
    localStorage.getItem("currentSiteId") ?? "";
  //filter
  const companyApi = CompanyApi();
  const siteApi = SiteApi();
  const [rentalCompanyDropdown, setRentalCompanyDropdown] = useState([] as any);
  const [useCompanyDropdown, setUseCompanyDropdown] = useState([] as any);
  const [siteIdSelected, setSiteIdSelected] = useState(
    localStorage.getItem("currentSiteId") ?? "0"
  );
  const [searchType, setSearchType] = useState<string>("list");
  const defaultFilterPayload = {
      type: "advance",
      company: "",
      rental_company: "",
      use_company: "",
      use_location: "",
      status: "",
      delivery_schedule_start_date: "",
      delivery_schedule_end_date: "",
      result_delivery_schedule_start_date: "",
      result_delivery_schedule_end_date: "",
      return_schedule_start_date: "",
      return_schedule_end_date: "",
      result_return_schedule_start_date: "",
      result_return_schedule_end_date: "",
      site_id: `${initialSelectedSiteId}`,
      last_use_actual_use_location: "",
      status_management: "",
    }
  const [filterPayload, setFilterPayload] = useState<{ [key: string]: string }>(
    defaultFilterPayload
  );
  const [locationList, setLocationList] = useState([] as any);
  const [categoryDataList, setCategoryDataList] = useState([] as any);
  const [selectedCategory1Name, setSelectedCategory1Name] = useState("");
  const [selectedCategory2Name, setSelectedCategory2Name] = useState("");
  const [secondaryCategoryList, setSecondaryCategoryList] = useState([] as any);
  const statusDropdown = [
    {
      value: "before_delivery",
      key: "filter_option_before_delivery",
      label: convertStatus("before_delivery"),
    },
    {
      value: "delivered",
      key: "filter_option_delivered",
      label: convertStatus("delivered"),
    },
    {
      value: "in_use",
      key: "filter_option_in_use",
      label: convertStatus("in_use"),
    },
    {
      value: "return",
      key: "filter_option_return",
      label: convertStatus("return"),
    },
  ];
  const [categoryFilterOff, setCategoryFilterOff] = useState(false);
  const [retrieveOnPopCompleted, setRetrieveOnPopCompleted] = useState(false);
  const [conditionRetrieved, setConditionRetrieved] = useState(false);
  const navigationType = useNavigationType();
  /// save conditions to retrieve when browser back
  const saveLastListConditions = async(searchType: string, lastSearchPayload: any, filterPayload: any, currentPageIndex: Number, siteIdSelected:string)=>{
    setlastSearchPayload(
      lastSearchPayload
    );
    //sessionStorageに保存。sessionStorageはログアウト時にクリアする
    sessionStorage.setItem("lastSearchType", searchType);
    sessionStorage.setItem("lastFilterPayload",JSON.stringify(filterPayload));
    sessionStorage.setItem("currentPageIndex",currentPageIndex.toString());
    sessionStorage.setItem("siteIdSelected",siteIdSelected);
    sessionStorage.setItem("lastSearchPayload",searchPayload);
  }
  /// retrieve conditions to get data when display this page with browser back
  const retrieveLastListConditions = async()=>{
    const searchTypeToSet = sessionStorage.getItem("lastSearchType") ?? 'list';
    const lastFilterPayload = sessionStorage.getItem("lastFilterPayload");
    const filterPayloadToSet = lastFilterPayload ? JSON.parse(lastFilterPayload) : defaultFilterPayload;
    const currentPageIndexToSet = Number.parseInt(sessionStorage.getItem("currentPageIndex") ?? '1');
    const siteIdSelectedToSet = sessionStorage.getItem("siteIdSelected") ?? siteIdSelected;
    const searchPayloadToSet = sessionStorage.getItem("lastSearchPayload") ?? "";
    if(filterPayloadToSet.equipment_category1_name){
      setSelectedCategory1Name(filterPayloadToSet.equipment_category1_name);
    }
    if(filterPayloadToSet.equipment_category2_name){
      setSelectedCategory2Name(filterPayloadToSet.equipment_category2_name);
    }
    setFilterPayload(filterPayloadToSet);
    setSearchType(searchTypeToSet);
    setCurrentPageIndex(currentPageIndexToSet);
    setSiteIdSelected(siteIdSelectedToSet);
    setSearchPayload(searchPayloadToSet);
    localStorage.setItem("currentSiteId", siteIdSelectedToSet);
    setRetrieveOnPopCompleted(true);
    setConditionRetrieved(true);
  }

  const filterElement = () => {
    return (
      <View
        as="div"
        border={`1px solid #757575`}
        borderRadius={8}
        padding={`25px`}
        marginBottom={20}
        fontWeight={`700`}
        minWidth={{'base': 'auto', 'medium': '880px'}}
        style={{'overflowX': 'scroll'}}
      >
        <View as="div" minWidth={{'base': 'auto', 'medium': '1230px'}}>
          <Flex gap={{'base': '0', 'medium': '25px'}} direction={{'base': 'column', 'medium': 'row'}}>
            <View as="div" className="left-filter">
              <Flex
                justifyContent={{ base: "", medium: "space-between" }}
                alignItems={{ base: "", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
                marginBottom={'15px'}
              >
                <View>カテゴリー（大）</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload["equipment_category1_name"]}
                  onChange={(e) => {
                    handleSelectChange(e, "equipment_category1_name");
                    setSelectedCategory1Name(e.target.value);
                  }}
                >
                  <option></option>
                  {categoryDataList?.map((item: any, index: any) => {
                    return (
                      <option
                        value={item?.equipment_category1_name}
                        key={`${item?.categoryId}`}
                      >
                        {item?.equipment_category1_name}
                      </option>
                    );
                  })}
                </SelectField>
              </Flex>
              <Flex
                justifyContent={{ base: "", medium: "space-between" }}
                alignItems={{ base: "", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
                marginBottom={'15px'}
              >
                <View>使用状況</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload.status_management}
                  onChange={(e) => handleSelectChange(e, "status_management")}
                >
                  <option></option>
                  {statusDropdown.map((item: any, index: any) => {
                    return (
                      <option value={item?.value} key={`${item?.key}`}>
                        {item?.label}
                      </option>
                    );
                  })}
                </SelectField>
              </Flex>
              <Flex
                justifyContent={{ base: "", medium: "space-between" }}
                alignItems={{ base: "", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
                marginBottom={'15px'}
              >
                <View>使用業者</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload["use_company"]}
                  onChange={(e) => handleSelectChange(e, "use_company")}
                >
                  <option></option>
                  {useCompanyDropdown?.map((item: any, index: any) => {
                    return (
                      <option
                        value={item?.company_id}
                        key={`use_company_${item?.company_id ?? index}-${
                          item?.company_name ?? index
                        }`}
                      >
                        {item?.company_name}
                      </option>
                    );
                  })}
                </SelectField>
              </Flex>
            </View>
            <View as="div" className="right-filter">
              <Flex
                justifyContent={{ base: "", medium: "space-between" }}
                alignItems={{ base: "", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
                marginBottom={'15px'}
              >
                <View>カテゴリー（中）</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload["equipment_category2_name"]}
                  onChange={(e) => {
                    handleSelectChange(e, "equipment_category2_name");
                    setSelectedCategory2Name(e.target.value);}
                  }
                >
                  <option></option>
                  {secondaryCategoryList?.map(
                    (equipment_category2_name_item: any, index: any) => {
                      return (
                        <option
                          value={`${equipment_category2_name_item}`}
                          key={`${equipment_category2_name_item}`}
                        >
                          {equipment_category2_name_item}
                        </option>
                      );
                    }
                  )}
                </SelectField>
              </Flex>
              <Flex
                justifyContent={{ base: "", medium: "space-between" }}
                alignItems={{ base: "", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
                marginBottom={'15px'}
              >
                <View>最終使用場所</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload["last_use_actual_use_location"]}
                  onChange={(e) =>
                    handleSelectChange(e, "last_use_actual_use_location")
                  }
                >
                  <option></option>
                  {locationList?.map((item: any, index: any) => {
                    return (
                      <option value={item?.name} key={index}>
                        {item?.name.length > 0 ? item?.name : "-"}
                      </option>
                    );
                  })}
                </SelectField>
              </Flex>
              <Flex
                justifyContent={{ base: "", medium: "space-between" }}
                alignItems={{ base: "", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
                marginBottom={'15px'}
              >
                <View>レンタル会社</View>
                <SelectField
                  label=""
                  width={`220px`}
                  icon={<SelectIcon />}
                  value={filterPayload["rental_company"]}
                  onChange={(e) => handleSelectChange(e, "rental_company")}
                >
                  <option></option>
                  {rentalCompanyDropdown?.map((item: any, index: any) => {
                    return (
                      <option
                        value={item?.company_id}
                        key={`rental_company_${item?.company_id ?? index}-${
                          item?.company_name ?? index
                        }`}
                      >
                        {item?.company_name}
                      </option>
                    );
                  })}
                </SelectField>
              </Flex>
            </View>
          </Flex>
          {/* date */}
          <Flex gap={'20px'} direction={{'base': 'column', 'medium': 'row'}}>
            <Flex
              gap={{ base: "10px", medium: "20px" }}
              alignItems={{ base: "flex-start", medium: "center" }}
              direction={{ base: "column", medium: "row" }}
            >
              <Flex
                justifyContent={`center`}
                alignItems={{ base: "flex-start", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
              >
                <View width={'112px'}>受入予定日</View>
                <Flex justifyContent={`center`} alignItems={`center`} direction={{ base: "column", medium: "row" }}>
                  <View display={`flex`} position={`relative`}>
                    <TextField
                      label=""
                      id="delivery_schedule_start_date"
                      placeholder=""
                      type="date"
                      onBlur={(event) =>
                        handleBlur(event, "delivery_schedule_start_date")
                      }
                      value={filterPayload.delivery_schedule_start_date}
                      onChange={(event) =>
                        handleDateChange(event, "delivery_schedule_start_date")
                      }
                      width={{ base: "180px", medium: "220px" }}
                    />
                    <View
                      position={`absolute`}
                      right={`21px`}
                      top={`36%`}
                      className="dateIcon"
                    >
                      <DateIcon />
                    </View>
                  </View>
                </Flex>
              </Flex>
              <View fontSize={16} lineHeight={`24px`}>
                〜
              </View>
              <Flex
                justifyContent={`center`}
                alignItems={`center`}
              >
                <View display={`flex`} position={`relative`}>
                  <TextField
                    label=""
                    id="delivery_schedule_end_date"
                    placeholder=""
                    type="date"
                    onBlur={(event) =>
                      handleBlur(event, "delivery_schedule_end_date")
                    }
                    value={filterPayload.delivery_schedule_end_date}
                    onChange={(event) =>
                      handleDateChange(event, "delivery_schedule_end_date")
                    }
                    width={{ base: "180px", medium: "220px" }}
                  />
                  <View
                    position={`absolute`}
                    right={`21px`}
                    top={`36%`}
                    className="dateIcon"
                  >
                    <DateIcon />
                  </View>
                </View>
              </Flex>
            </Flex>
            <Flex
              gap={{ base: "0", medium: "20px" }}
              alignItems={{ base: "", medium: "center" }}
              direction={{ base: "column", medium: "row" }}
            >
              <Flex
                gap={{ base: "10px", medium: "20px" }}
                alignItems={{ base: "", medium: "center" }}
                direction={{ base: "column", medium: "row" }}
              >
                <Flex
                  justifyContent={`center`}
                  alignItems={{ base: "flex-start", medium: "center" }}
                  direction={{ base: "column", medium: "row" }}
                >
                 <View>返却予定日</View>
                  <Flex justifyContent={`center`} alignItems={`center`}>
                    <View display={`flex`} position={`relative`}>
                      <TextField
                        label=""
                        id="return_schedule_start_date"
                        placeholder=""
                        type="date"
                        onBlur={(event) =>
                          handleBlur(event, "return_schedule_start_date")
                        }
                        value={filterPayload.return_schedule_start_date}
                        onChange={(event) =>
                          handleDateChange(event, "return_schedule_start_date")
                        }
                        width={{ base: "180px", medium: "220px" }}
                      />
                      <View
                        position={`absolute`}
                        right={`21px`}
                        top={`36%`}
                        className="dateIcon"
                      >
                        <DateIcon />
                      </View>
                    </View>
                  </Flex>
                </Flex>
                <View fontSize={16} lineHeight={`24px`}>
                  〜
                </View>
                <Flex
                  justifyContent={`center`}
                  alignItems={`center`}
                  className="eq-filter-field"
                >
                  <View display={`flex`} position={`relative`}>
                    <TextField
                      label=""
                      id="return_schedule_end_date"
                      placeholder=""
                      type="date"
                      onBlur={(event) =>
                        handleBlur(event, "return_schedule_end_date")
                      }
                      value={filterPayload.return_schedule_end_date}
                      onChange={(event) =>
                        handleDateChange(event, "return_schedule_end_date")
                      }
                      width={{ base: "180px", medium: "220px" }}
                    />
                    <View
                      position={`absolute`}
                      right={`21px`}
                      top={`36%`}
                      className="dateIcon"
                    >
                      <DateIcon />
                    </View>
                  </View>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex justifyContent={`center`} marginTop={20}>
            <CustomButton
              {...defaultButtonProps}
              text="上記で絞り込む"
              type="secondary"
              size="medium"
              click={() => {
                setCurrentPageIndex(1);
                handleFilterSubmit(itemsLimit, 1);
              }}
            />
          </Flex>
        </View>
      </View>
    );
  };

  const searchEqElement = () => {
    return (
      <View
        as="form"
        className="hide-from-print"
        marginBottom={{ base: "32px", medium: "40px" }}
        onSubmit={(e) => e.preventDefault()}
      >
        <SearchField
          placeholder="検索"
          label="search"
          onChange={(event) => {
            handleChange(event.target.value);
          }}
          value={searchPayload}
          onSubmit={() => {
            setCurrentPageIndex(1);
            searchEq("freeword", itemsLimit, 1);
          }}
          className="search-eq search-sp"
        />
        <Flex
          flex={1}
          justifyContent={{ base: "space-between", medium: "" }}
          gap={{ base: "", medium: "60px" }}
          marginTop="24px"
        >
          <View
            as="div"
            display="flex"
            style={{ gap: "8px", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleCategory()}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.3333 7.1013V3.31797C18.3333 2.14297 17.8 1.66797 16.475 1.66797H13.1083C11.7833 1.66797 11.25 2.14297 11.25 3.31797V7.09297C11.25 8.2763 11.7833 8.74297 13.1083 8.74297H16.475C17.8 8.7513 18.3333 8.2763 18.3333 7.1013Z"
                fill="#A9ABAD"
              />
              <path
                d="M18.3333 16.475V13.1083C18.3333 11.7833 17.8 11.25 16.475 11.25H13.1083C11.7833 11.25 11.25 11.7833 11.25 13.1083V16.475C11.25 17.8 11.7833 18.3333 13.1083 18.3333H16.475C17.8 18.3333 18.3333 17.8 18.3333 16.475Z"
                fill="#4365DE"
              />
              <path
                d="M8.75002 7.1013V3.31797C8.75002 2.14297 8.21669 1.66797 6.89169 1.66797H3.52502C2.20002 1.66797 1.66669 2.14297 1.66669 3.31797V7.09297C1.66669 8.2763 2.20002 8.74297 3.52502 8.74297H6.89169C8.21669 8.7513 8.75002 8.2763 8.75002 7.1013Z"
                fill="#4365DE"
              />
              <path
                d="M8.75002 16.475V13.1083C8.75002 11.7833 8.21669 11.25 6.89169 11.25H3.52502C2.20002 11.25 1.66669 11.7833 1.66669 13.1083V16.475C1.66669 17.8 2.20002 18.3333 3.52502 18.3333H6.89169C8.21669 18.3333 8.75002 17.8 8.75002 16.475Z"
                fill="#A9ABAD"
              />
            </svg>
            <View as="p" fontSize="16px" color="#304050">
              カテゴリーから選ぶ
            </View>
          </View>

          <View
            as="div"
            display="flex"
            style={{ gap: "8px", alignItems: "center", cursor: "pointer" }}
            onClick={() => handleFilterClick()}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.50834 1.66797H15.4834C16.4 1.66797 17.15 2.42629 17.15 3.35129V5.20129C17.15 5.87629 16.7334 6.71797 16.3167 7.13463L12.7417 10.3346C12.2417 10.7596 11.9083 11.6013 11.9083 12.268V15.8846C11.9083 16.393 11.575 17.0596 11.1584 17.318L9.99169 18.0763C8.90836 18.7513 7.41672 17.993 7.41672 16.643V12.1846C7.41672 11.593 7.08334 10.8346 6.75001 10.418L3.5917 7.05129C3.17503 6.6263 2.84167 5.8763 2.84167 5.36797V3.43463C2.85001 2.4263 3.60001 1.66797 4.50834 1.66797Z"
                fill="#A9ABAD"
              />
              <path
                d="M16.4583 12.843L15.75 12.1346C16.1167 11.5763 16.3333 10.918 16.3333 10.2013C16.3333 8.25129 14.75 6.66797 12.8 6.66797C10.85 6.66797 9.26666 8.25129 9.26666 10.2013C9.26666 12.1513 10.85 13.7346 12.8 13.7346C13.5167 13.7346 14.1833 13.518 14.7333 13.1513L15.4417 13.8596C15.5834 14.0013 15.7667 14.068 15.95 14.068C16.1333 14.068 16.3167 14.0013 16.4583 13.8596C16.7333 13.5763 16.7333 13.118 16.4583 12.843Z"
                fill="#4365DE"
              />
            </svg>
            <View as="p" fontSize="16px" color="#304050">
              絞り込む
            </View>
          </View>
        </Flex>
      </View>
    );
  };

  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { label: "機材管理" },
    { href: "", label: "機材一覧" },
  ];

  const defaultButtonProps = {
    text: "",
    type: "primary",
    iconPosition: "left",
    iconName: "",
    size: "",
    disabled: false,
    click: () => {
      return "";
    },
  };

  const handleAdd = async () => {
    navigate("/eq/create");
  };

  useEffect(() => {
    if (isActionsModalOpen) {
      // @ts-ignore
      document.querySelector(".main-body")?.classList.add("hidden");
    }
  }, [isActionsModalOpen]);

  const handleActions = (id: any, name: string) => {
    setIdSelected(id);
    setNameSelected(name);
    // @ts-ignore
    document.querySelector(".main-body")?.classList.add("hidden");
    // document.querySelector(".main-body").style.overflow = "hidden";
    document
      .querySelector(".main-body")
      ?.scroll({ top: 0, behavior: "smooth" });
    setIsActionsModalOpen(true);
  };

  const closeActions = () => {
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains("hidden")) {
      document.querySelector(".main-body")?.classList.remove("hidden");
    }
    // document.querySelector(".main-body").style.overflow = "auto";
    setIsActionsModalOpen(false);
  };

  const handleEdit = async () => {
    // @ts-ignore
    if (document.querySelector(".main-body")?.classList.contains("hidden")) {
      document.querySelector(".main-body")?.classList.remove("hidden");
    }
    // document.querySelector(".main-body").style.overflow = "auto";
    navigate(`/eq/edit/${idSelected}`);
  };

  const handleDelete = async () => {
    deleteEq(idSelected);
  };

  const handleUpload = async () => {
    navigate("/eq/csv/upload");
  };

  const convertToCSV = (data: any[]) => {
    const headers = [
     `"機材名"`,
     `"カテゴリー大"`,
     `"カテゴリー中"`,
     `"現場管理No"`,
     `"使用状況"`,
     `"最終使用場所"`,
     `"使用業者"`,
     `"レンタル会社"`,
     `"レンタル会社商品名"`,
     `"数量"`,
     `"返却予定日"`,
     `"発注担当者"`,
     `"稼働状況"`,
     `"総稼働時間"`,
     `"受入時稼働時間"`,
     `"最終棚卸日"`,
     `"備考"`,
     `"最終更新日"`
    ];
    const array = [
      headers.join(","),
      ...data.map((item) =>
        [
          `"${item.common_name?.replaceAll("-","") !== "" ? item.common_name?.replaceAll("-","") : item.name?.replaceAll("-","") ?? ''}"`,
          `"${item.category?.replaceAll("-","") ?? ''}"`,
          `"${item.category2?.replaceAll("-","") ?? ''}"`,
          `"${item.onsite_manage_no?.replaceAll("-","") ?? ''}"`,
          `"${item.condition?.replaceAll("-","") ?? ''}"`,  //使用状況をいれる
          `"${item.last_use_actual_use_location?.replaceAll("-","") ?? ''}"`,
          `"${item.condition === '使用中' ? (item.last_use_actual_company_name?.replaceAll("-","") ?? ''): ''}"`,
          `"${item.rentalCompany?.replaceAll("-","") ?? ''}"`,
          `"${item.name?.replaceAll("-","") ?? ''}"`,
          `"${item.quantity?.replaceAll("-","") ?? ''}"`,
          `"${item.returnDate?.replaceAll("-","") ?? ''}"`,
          `"${item.charge_ordering?.replaceAll("-","") ?? ''}"`,
          `"-"`, //稼働状況を-にする
          `"${item.total_operation_time?.replaceAll("-","") ?? ''}"`,
          `"${item.operation_time_at_start_use?.replaceAll("-","") ?? ''}"`,
          `"${item.inventory_datetime ?? ''}"`,
          `"${item.remarks?.replaceAll("-","") ?? ''}"`,
          `"${item.eq_updated_at ?? ''}"`,
          //`"${item.category ?? ''}"`,
        ].join(",")
      ),
    ];
    return array.join("\n");
  };

  const handleDownload = async () => {
    try {
      const { data } = await api.searchEqAdvance(
        lastSearchPayload.type,
        lastSearchPayload.payload,
        Number.MAX_SAFE_INTEGER,
        1
      );
      if (!data?.success) {
        alert('CSV出力に失敗しました。時間をおいて再度お試しください')
        return;
      }
      const mappedList = mapEqlist(data);
      const csvData = convertToCSV(mappedList);
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "equipment-list.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e){
      alert('CSV出力に失敗しました。時間をおいて再度お試しください')
    }
  };

  const handleCategory = async () => {
    navigate("/categories/list");
  };

  const mapEqlist = (data: any) => {
    const mappedList: IEq[] = data?.data.map((item: any) => {
      return {
        id: item?.equipment_id || "-",
        name: item?.equipment_common_name || item?.equipment_name || "-",
        common_name: item?.equipment_common_name || "",
        //model: item?.model || "-",
        onsite_manage_no: item?.onsite_manage_no || "-",
        category: item?.equipment_category1_name || "-",
        category2: item?.equipment_category2_name || "-",
        condition: convertStatus(item?.status_management) || "-",
        contractor: item?.users?.users?.name || "-",
        last_use_actual_use_location: item?.last_use_actual_use_location || "-",
        last_use_actual_company_name: item?.last_use_actual_company_name || "-",
        rentalCompany: item?.company_name || "-",
        returnDate: formatDate("dot", item?.return_schedule) || "-",
        lastDate: formatDate("dot", item?.result_delivery_schedule) || "-",
        // for csv
        charge_ordering: item?.charge_ordering || "-",
        quantity: item?.quantity || "-",
        remarks: item?.remarks || "-",
        total_operation_time: item?.total_operating_hours,
        operation_time_at_start_use: item?.operation_time_at_start_use || "-",
        eq_updated_at: item?.eq_updated_at || "-",
        inventory_datetime: item?.inventory_datetime || "-"
      };
    });

    return mappedList;
  };

  //equipmentListGet1, afterPagenation from site control
  const getList = async (limit: number = itemsLimit, page: number = 1) => {
    const payload = {
      type: "advance",
      category_id: state?.categoryId,
      site_id: siteIdSelected,
    };
    const sitePayload = {
      type: "advance",
      // @ts-ignore
      site_id: siteIdSelected,
    };
    setLoader(true);
    const { data } = state?.categoryId && !categoryFilterOff
      ? await api.searchEqAdvance(payload?.type, payload, limit, page)
      : await api.searchEqAdvance(sitePayload?.type, sitePayload, limit, page);
    const lastSearchPayloadToSet = state?.categoryId && !categoryFilterOff
      ? {
          type: payload?.type,
          payload: payload
        }
      : {
          type: sitePayload?.type,
          payload: sitePayload
        }
    saveLastListConditions(searchType, lastSearchPayloadToSet, filterPayload, page, siteIdSelected);
    if (data?.success) {
      const mappedList = mapEqlist(data);
      setDataList(mappedList);
      setTotalPages(data?.data?.totalPages || data?.meta?.totalPages);
      setTotalCount(data?.data?.totalCount || data?.meta?.totalCount);
      setLoader(false);
      setFetchError(false);
    } else {
      setFetchError(true);
    }
  };

  const getLocationList = async (id: any) => {
    // setLoader(true);
    try {
      const { data } = await siteApi.getDetail(id);
      if (data?.success) {
        setLocationList([...data?.data?.site_construction_locations]);
      } else {
        setLocationList([]);
      }
    } catch (err) {
      return err;
    }
  };

  //EquipmentListGet2
  const searchEq = async (
    type: string,
    limit: number = itemsLimit,
    page: number = currentPageIndex
  ) => {
    var searchTypeToSet = "";
    if (searchPayload.trim() === "") {
      searchTypeToSet = "list"
      setSearchType(searchTypeToSet);//リストに戻す
      setCurrentPageIndex(1);
      saveLastListConditions(searchTypeToSet, defaultFilterPayload, defaultFilterPayload, 1, siteIdSelected);
      getList(limit, 1);
      return;
    }
    searchTypeToSet = "freeword"
    setSearchType(searchTypeToSet);
    setLoader(true);
    try {
      const { data } = await api.searchEqAdvance(
        type,
        {
          freeword: searchPayload,
          site_id: filterPayload.site_id,
        },
        limit,
        page
      );
      const lastSearchPayloadToSet ={
          type: type,
          payload:{
           freeword: searchPayload,
           site_id: filterPayload.site_id
          }
        }
      saveLastListConditions(searchTypeToSet, lastSearchPayloadToSet, filterPayload, page, siteIdSelected);
      if (data?.success) {
        const mappedList = mapEqlist(data);
        setDataList(mappedList);
        setTotalPages(data?.data?.totalPages || data?.meta?.totalPages);
        setTotalCount(data?.data?.totalCount || data?.meta?.totalCount);
        setLoader(false);
        setFetchError(false);
      } else {
        setFetchError(true);
      }
    } catch (err) {
      //@ts-ignore
      if(err.response.status === 401){
        console.log(err);
        window.location.reload();
        return err;
      }
      console.log(err);
      return err;
    }
  };
  //

  const handleNextPage = () => {
    setCurrentPageIndex(currentPageIndex + 1);
    switch (searchType) {
      case "list":
        return getList(itemsLimit, currentPageIndex + 1);
      case "freeword":
        return searchEq("freeword", itemsLimit, currentPageIndex + 1);
      case "filter":
        return handleFilterSubmit(itemsLimit, currentPageIndex + 1);
    }
  };
  //

  const handlePreviousPage = () => {
    setCurrentPageIndex(Math.max(1, currentPageIndex - 1));
    switch (searchType) {
      case "list":
        return getList(itemsLimit, currentPageIndex - 1);
      case "freeword":
        return searchEq("freeword", itemsLimit, currentPageIndex - 1);
      case "filter":
        return handleFilterSubmit(itemsLimit, currentPageIndex - 1);
    }
  };

  const handleOnChange = (newPageIndex: number) => {
    setCurrentPageIndex(newPageIndex);
  };

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const { value } = event.target;
    setFilterPayload((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleCellClick = (
    e: React.MouseEvent<HTMLTableRowElement | HTMLDivElement, MouseEvent>,
    id: number
  ) => {
    const target = e.target as HTMLTableCellElement;
    if (
      target.className === "amplify-table__td" ||
      target.namespaceURI !== "http://www.w3.org/2000/svg"
    ) {
      navigate(`/eq/${id}`);
    }
  };

  const handleChange = (value: any) => {
    setSearchPayload(value);
  };

  const deleteEq = async (id: any) => {
    setDisableButton(true);
    setErrorMessage("");
    try {
      const { data } = await api.deleteEq(id);
      if (data?.success) {
        setDisableButton(false);
        setIsActionsModalOpen(false);
        setIsDeleteModalOpen(false);
        setHasError(false);
        setErrorMessage("");
        setCurrentPageIndex(1);
        getList();

        // @ts-ignore
        // document.querySelector(".main-body").style.overflow = "auto";
      } else {
        setDisableButton(false);
        setIsActionsModalOpen(false);
        setIsDeleteModalOpen(false);
        setHasError(true);
        setErrorMessage(data?.error?.message ?? data?.message);

        // @ts-ignore
        // document.querySelector(".main-body").style.overflow = "auto";
      }

      // @ts-ignore
      if (document.querySelector(".main-body")?.classList.contains("hidden")) {
        document.querySelector(".main-body")?.classList.remove("hidden");
      }
    } catch (err) {
      　console.log(err);
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement>,
    field: string
  ) => {
    // const { relatedTarget } = event;
    // if (!relatedTarget || !relatedTarget.id.startsWith('date')) {
    //   if (!filterPayload[field]) {
    //     setFilterPayload(prevState => ({
    //       ...prevState,
    //       [field]: '',
    //     }));
    //   }
    // }
  };

  const handleFilterClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleFilterSubmit = async (
    limit: number = itemsLimit,
    page: number = 1
  ) => {
    setSearchType("filter");
    setLoader(true);
    const { data } = await api.searchEqAdvance(
      filterPayload.type,
      filterPayload,
      limit,
      page
    );
    const lastSearchPayloadToSet = {
        type: filterPayload.type,
        payload: filterPayload
      }
    saveLastListConditions("filter", lastSearchPayloadToSet, filterPayload, page, siteIdSelected);
    if (data?.success) {
      const mappedList = mapEqlist(data);
      setDataList(mappedList);
      setTotalPages(data?.data?.totalPages || data?.meta?.totalPages);
      setTotalCount(data?.data?.totalCount || data?.meta?.totalCount);
      setLoader(false);
      setFetchError(false);
      setShowDropdown(false);
    } else {
      setFetchError(true);
    }
  };

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    field: string
  ) => {
    setFilterPayload((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  //現場プルダウンで選択後の現場情報を取得する関数（OK）
  const setSiteForFilter = async (value: any) => {
    localStorage.setItem("currentSiteId", value);
    setFilterPayload((prevState) => ({
      ...prevState,
      site_id: value,
    }));
    setCurrentPageIndex(1); //選択する現場を変えた時にリストの一ページ目を表示させるため
    setSearchType("list"); //listに戻す
    const payload = {
      type: "advance",
      category_id: state?.categoryId,
      site_id: value,
    };
    const sitePayload = {
      type: "advance",
      // @ts-ignore
      site_id: value,
    };
    if(!categoryFilterOff){
      setCategoryFilterOff(true);
    }
    setLoader(true);
    const { data } = state?.categoryId && !categoryFilterOff && false//categoryFilterはここでは使わない
      ? await api.searchEqAdvance(
          payload?.type,
          payload,
          itemsLimit,
          currentPageIndex
        )
      : await api.searchEqAdvance(
          sitePayload?.type,
          sitePayload,
          itemsLimit,
          currentPageIndex
        );
    const lastSearchPayloadToSet = state?.categoryId && !categoryFilterOff ?
      {
        type: payload?.type,
        payload: payload,
      } : {
        type: sitePayload?.type,
        payload: sitePayload,
      }
    saveLastListConditions(searchType, lastSearchPayloadToSet, filterPayload, 1, siteIdSelected);
    if (data?.success) {
      setSiteIdSelected(value);
      const mappedList = mapEqlist(data);
      setDataList(mappedList);
      setTotalPages(data?.data?.totalPages || data?.meta?.totalPages);
      setLoader(false);
      setFetchError(false);
      setShowDropdown(false);
    } else {
      setFetchError(true);
    }
  };

  useEffect(() => {
    if (!settings || !company) {
      return;
    }
    const getCompanyList = async () => {
      try {
        const { data, status } = await companyApi.getList();
        if (status === 200) {
          if (
            (company && company.admin_flag === true) ||
            (settings &&
              settings[siteIdSelected] &&
              settings[siteIdSelected]["record_control-equipments_manage"])
          ) {
            const filteredCompanies = data?.data?.filter((e: any) => {
              return (
                e.company_category_id === "200" &&
                (company.admin_flag === true ||
                settings[siteIdSelected][
                  "record_control-equipments_manage"
                ].includes(e.company_id))
              );
            });
            setRentalCompanyDropdown(filteredCompanies);
          } else {
            setRentalCompanyDropdown([]);
          };
          if (
            (company && company.admin_flag === true) ||
            (settings &&
              settings[siteIdSelected] &&
              settings[siteIdSelected]["record_control-equipments_schedule_manage"])
          ) {
            const filteredCompanies = data?.data?.filter((e: any) => {
              return (
                e.company_category_id !== "200" &&
                (company.admin_flag === true ||
                settings[siteIdSelected][
                  "record_control-equipments_schedule_manage"
                ].includes(e.company_id))  
              );
            });
            setUseCompanyDropdown(filteredCompanies);
          } else {
            setUseCompanyDropdown([]);
          };
        }else if(status === 401){
          window.location.reload();
          console.log("テスト2")
        }
      } catch (e) {
        //@ts-ignore
        if (e.message.includes('CORS') || e.message.includes('Failed to fetch')) {
          console.log('CORSエラーが発生したためページをリロードします...');
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        console.error(e);
        }
      }
    };
    // filterを出すための
    getCompanyList();
    // eslint-disable-next-line
  }, [settings, company, siteIdSelected]);


  useEffect(() => {
    //getList();
    if (company?.admin_flag) {
      const setAdminSiteList = async () => {
        const { data, status } = await siteApi.getList();
        if (status === 200) {
          const mappedSiteList = data?.data.map(
            (site: { site_id: any; site_name: any }) => ({
              value: site.site_id,
              label: site.site_name,
            })
          );

          if (!mappedSiteList) {
            return;
          }
          if (initialSelectedSiteId) {
            {
              /*setSiteSelections([
              ...mappedSiteList.filter(
                (e: any) => e.value === initialSelectedSiteId
              ),
              ...mappedSiteList.filter(
                (e: any) => e.value !== initialSelectedSiteId
              ),
            ]);*/
            }
            setSiteIdSelected(initialSelectedSiteId);
            setSiteSelections(mappedSiteList);
          } else {
            setSiteSelections(mappedSiteList);
            setSiteIdSelected(mappedSiteList[0].value);
            setFilterPayload((prevState) => ({
              ...prevState,
              site_id: mappedSiteList[0].value,
            }));
          }
        }
      };
      setAdminSiteList();
    } else {
      const mappedSiteList: SiteSelect[] = site?.map((item: any) => {
        return {
          value: item.site_id,
          label: item.site_name,
        };
      });
      if (!mappedSiteList) {
        return;
      }
      if (
        initialSelectedSiteId &&
        mappedSiteList.some((e) => e.value === initialSelectedSiteId)
      ) {
        {
          /*setSiteSelections([
          ...mappedSiteList.filter(
            (e: any) => e.value === initialSelectedSiteId
          ),
          ...mappedSiteList.filter(
            (e: any) => e.value !== initialSelectedSiteId
          ),
        ]);*/
        }
        setSiteSelections(mappedSiteList);
        setSiteIdSelected(initialSelectedSiteId);
      } else {
        setSiteSelections(mappedSiteList);
        setSiteForFilter(mappedSiteList[0].value);
        setSiteIdSelected(mappedSiteList[0].value);
      }
    }

    // eslint-disable-next-line
  }, [site, company]);

  {
    /*useEffect(()=>{    
    // siteIdSelectedを定義
    if(siteSelections && siteSelections.length > 0 && `${siteIdSelected}` !== `${siteSelections[0]?.value}`){
      setSiteIdSelected(siteSelections[0]?.value)
    };
      //siteSelections && siteSelections.length
      //  ? siteSelections[0]?.value
      //  : site[0]?.site_id
  },[siteSelections]);*/
  }

  useEffect(() => {
    if (siteIdSelected !== "0") {
      getLocationList(siteIdSelected);
      if(navigationType === NavigationType.Pop && !conditionRetrieved){//browser back or forward. call only once.
          retrieveLastListConditions();
      }else{
        getList(itemsLimit, currentPageIndex);
      }
    } else {
      console.log("wait");
    }
    // eslint-disable-next-line
  }, [siteIdSelected]);

  useEffect(()=>{
    if(retrieveOnPopCompleted){
      switch (searchType) {
        case "list":
           getList(itemsLimit, currentPageIndex);
           break;
        case "freeword":
           searchEq("freeword", itemsLimit, currentPageIndex);
           break;
        case "filter":
           handleFilterSubmit(itemsLimit, currentPageIndex);
           break;
      }
      setRetrieveOnPopCompleted(false);
    }
  },[retrieveOnPopCompleted])

  useEffect(() => {
    //中カテゴリーの絞り込みがすでに行われていた場合に保持する処理
    const lastFilterPayload = sessionStorage.getItem("lastFilterPayload");
    const filterPayloadToSet = lastFilterPayload ? JSON.parse(lastFilterPayload) : defaultFilterPayload;
    const category1 = categoryDataList?.filter(
      (categ: any) => categ.equipment_category1_name === selectedCategory1Name
    );
    //console.log(!filterPayloadToSet.equipment_category2_name);
    if (category1.length > 0) {
      setSecondaryCategoryList(category1[0].equipment_category2_name);
      if(!filterPayloadToSet.equipment_category2_name){
        setFilterPayload((prevState) => ({
          ...prevState,
          equipment_category2_name: "",
        }));
      }
    } else {
      setSecondaryCategoryList([]);
    }

    //setSecondaryCategories(
    //categoryDataList.filter((item:any)=>item.equipment_category1_name === selectedCategory1)
    //)
  }, [categoryDataList, selectedCategory1Name]);

  const getCategoryList = async () => {
    setLoader(true);
    const { data } = await api.getCategoryList();
    if (data?.success) {
      const mappedList: IEqCategoryList = data?.data?.map((item: any) => {
        return {
          equipment_category1_name: item.equipment_category1_name,
          equipment_category2_name: Object.values(
            item.secondary_equipment_categories
          ),
          quantity: item.secondary_equipment_categories.length,
          categoryId: item.equipment_category_id,
        };
      });
      setCategoryDataList(mappedList);
      setLoader(false);
      setFetchError(false);
    } else {
      setFetchError(true);
    }
  };

  useEffect(() => {
    // filter
    getCategoryList();
  }, []);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(getDeviceType() === "mobile");
    };
    window.addEventListener("resize", updateIsMobile);
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  useEffect(() => {
    const firstIndex = (indexOfLastUser - itemsLimit) + 1
    const itemsPerPage = totalCount - (currentPageIndex-1)*itemsLimit
    firstIndex <= 0 ? setIndexOfFirstUser(1) : setIndexOfFirstUser(firstIndex)
    if(currentPageIndex === totalPages) {
      setIndexOfLastUser(totalCount);
      setIndexOfFirstUser(totalCount - itemsPerPage+1);
    } else if(currentPageIndex > totalPages){
      setIndexOfLastUser(0);
      setIndexOfFirstUser(0);
    }else {
      setIndexOfLastUser(currentPageIndex * itemsLimit);
      setIndexOfFirstUser(firstIndex)
    }
  }, [currentPageIndex, indexOfLastUser, totalCount, totalPages])

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}  
      title={pageTitle}
      showSelectField={true}
      selectFieldOptions={siteSelections}
      initialValue={siteIdSelected}
      changeEvent={setSiteForFilter}
    >
      <Flex
        className="hide-from-print"
        alignItems={"center"}
        direction={{
          base: "column",
          medium: "row",
        }}
        paddingBottom={{
          base: "30px",
          medium: "40px",
        }}
      >
        {(company?.admin_flag === true ||
          (settings &&
            settings[siteIdSelected]?.page_control?.includes(
              "equipment_regist"
            ))) && (
          <CustomButton
            {...defaultButtonProps}
            text="新規機材登録"
            size="medium"
            width={"full"}
            borderRadius={"large"}
            disabled={false}
            click={() => handleAdd()}
          />
        )}
        {(company?.admin_flag === true ||
          (settings &&
            settings[siteIdSelected]?.page_control?.includes(
              "equipment_csv_regist"
            ))) && (
          <CustomButton
            {...defaultButtonProps}
            text="CSV一括登録"
            size="medium"
            width={"full"}
            borderRadius={"large"}
            click={() => handleUpload()}
          />
        )}
        <CustomButton
          {...defaultButtonProps}
          text="CSVダウンロード"
          type="light-gray"
          size="medium"
          width={"full"}
          borderRadius={"large"}
          click={() => handleDownload()}
        />
      </Flex>
      {!isMobile ? (
        <View
          className="custom-table"
          marginTop="0"
          paddingTop="40px"
          style={{ borderTop: "1px solid #DCE4E8" }}
          overflow={'hidden'}
        >
          {hasError ? (
            <Flex className="signin-error-message">
              <View className="error-icon">
                <ErrorSmallIcon fill="#D32F2F" />
              </View>
              <Text>{errorMessage}</Text>
            </Flex>
          ) : null}
          {searchEqElement()}
          {!loader ? (
            <>
              {showDropdown && filterElement()}
              <View as="div" className="scrollable-pc">
                <Table
                  highlightOnHover={true}
                  className="eq-list"
                  minWidth="1400px"
                >
                  <TableHead>
                    <TableRow backgroundColor="#edf0f8">
                      <TableCell as="th" width={'25%'}>機材名</TableCell>
                      <TableCell as="th">現場管理NO.</TableCell>
                      <TableCell as="th" textAlign={"center"}>
                        使用状況
                      </TableCell>
                      <TableCell as="th">最終使用場所</TableCell>
                      <TableCell as="th">使用業者</TableCell>
                      <TableCell as="th">レンタル会社</TableCell>
                      <TableCell as="th" textAlign={'center'}>返却予定日</TableCell>
                      {/*<TableCell as="th">最終更新日</TableCell>*/}
                      {(company?.admin_flag === true ||
                        (settings &&
                          settings[siteIdSelected]?.page_control?.includes(
                            "equipment_edit"
                          )) ||
                        (settings &&
                          settings[siteIdSelected]?.page_control?.includes(
                            "equipment_delete"
                          ))) && (
                        <TableCell
                          className="hide-from-print"
                          as="th"
                          textAlign={"center"}
                          width={'115px'}
                        >
                          編集・削除
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody className="vertical-scroll">
                    {dataList?.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={10} textAlign={"center"}>
                          <Text>No data found.</Text>
                        </TableCell>
                      </TableRow>
                    )}
                    {dataList?.map((item: any, index: number) => {
                      return (
                        <TableRow
                          onClick={(e) => handleCellClick(e, item.id)}
                          key={index}
                          className="eq-table-row"
                        >
                          <TableCell width={'25%'}>{item.common_name || item.name}</TableCell>
                          {/*<TableCell>{item.model}</TableCell>*/}
                          <TableCell>{item.onsite_manage_no}</TableCell>
                          <TableCell>
                            <View
                              as="span"
                              backgroundColor={getBadgeColor(item.condition).bg}
                              color={getBadgeColor(item.condition).color}
                              display="block"
                              fontSize="12px"
                              fontWeight="700"
                              padding="5px 0"
                              borderRadius="6px"
                              height="27px"
                              width="100%"
                              textAlign="center"
                              maxWidth={'80px'}
                              margin={'0 auto'}
                            >
                              {item.condition}
                            </View>
                          </TableCell>
                          {/*<TableCell>{item.contractor}</TableCell>*/}
                          <TableCell>
                            {item.last_use_actual_use_location}
                          </TableCell>
                          <TableCell>
                            {item.condition === "使用中"
                              ? item.last_use_actual_company_name
                              : "-"}
                          </TableCell>
                          <TableCell>{item.rentalCompany}</TableCell>
                          <TableCell textAlign={'center'}>{item.returnDate}</TableCell>
                          {/*<TableCell>{item.lastDate}</TableCell>*/}
                          {(company?.admin_flag === true ||
                            (settings &&
                              settings[siteIdSelected]?.page_control?.includes(
                                "equipment_edit"
                              )) ||
                            (settings &&
                              settings[siteIdSelected]?.page_control?.includes(
                                "equipment_delete"
                              ))) && (
                            <TableCell
                              className="hide-from-print"
                              as="th"
                              textAlign={"center"}
                              width={'115px'}
                            >
                              <CustomButton
                                {...defaultButtonProps}
                                type="icon"
                                iconName="menu"
                                className="no-min"
                                click={() => handleActions(item.id, item.name)}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </View>
              <Flex
                justifyContent="center"
                alignItems="center"
                marginTop="20px"
                fontWeight={400}
                fontSize={12}
                letterSpacing={0.4}
                className="hide-from-print"
                direction={"column"}
              >
                <View>
                  {totalCount +
                    "件中" +
                    indexOfFirstUser +
                    "-" +
                    indexOfLastUser +
                    "件"}
                </View>
                <IconsProvider
                  icons={{
                    pagination: {
                      next: <ArrowRightIcon />,
                      previous: <ArrowLeftIcon />,
                    },
                  }}
                >
                  <Pagination
                    currentPage={currentPageIndex}
                    onNext={handleNextPage}
                    onPrevious={handlePreviousPage}
                    totalPages={totalPages}
                    // @ts-ignore
                    onChange={handleOnChange}
                    className={`custom-pagination ${
                      currentPageIndex === totalPages ? "disabled" : ""
                    } ${currentPageIndex === 1 ? "firstPage" : ""}`}
                  />
                </IconsProvider>
              </Flex>
            </>
          ) : (
            <View className="table-loader">
              <Loader width="5rem" height="5rem" filledColor="#2196F3" />
            </View>
          )}
        </View>
      ) : (
        <View padding={"40px 20px"}>
          {searchEqElement()}
          <View style={{ borderBottom: "1px solid #DCE4E8" }}>
            {showDropdown && filterElement()}
            <Flex direction="column" gap="0">
              {dataList?.length === 0 && (
                <View textAlign={"center"}>
                  <Text>No data found.</Text>
                </View>
              )}
              {dataList?.map((item: any, index: any) => {
                return (
                  <View
                    className="eq-list-item"
                    onClick={(e) => handleCellClick(e, item.id)}
                    key={`sp-${item.id}-${index}`}
                    as="div"
                    style={{ borderTop: "1px solid #DCE4E8" }}
                    padding="16px"
                    paddingRight="2px"
                    position="relative"
                  >
                    <Flex justifyContent="space-between" marginBottom="10px">
                      <View as="p" fontWeight="700" fontSize="14px" margin="0">
                        {item.common_name || item.name}
                      </View>
                      <View as="div">
                        {(company?.admin_flag === true ||
                          (settings &&
                            settings[siteIdSelected]?.page_control?.includes(
                              "equipment_edit"
                            )) ||
                          (settings &&
                            settings[siteIdSelected]?.page_control?.includes(
                              "equipment_delete"
                            ))) && (
                          <CustomButton
                            {...defaultButtonProps}
                            type="icon"
                            iconName="menu"
                            className="no-min"
                            click={() => handleActions(item.id, item.name)}
                          />
                        )}
                      </View>
                    </Flex>
                    <Flex direction="column" gap="4px">
                      <Flex gap="10px">
                        <View
                          as="p"
                          flex="0 110px"
                          fontSize="12px"
                          textAlign="center"
                          padding="2px 4px"
                          margin="0"
                          color="#6C7278"
                          backgroundColor="#EDF0F8"
                        >
                          現場管理NO.
                        </View>
                        <View
                          as="p"
                          flex="1"
                          fontSize="12px"
                          margin="0"
                          color="#6C7278"
                        >
                          {item.onsite_manage_no}
                        </View>
                      </Flex>
                      <Flex gap="10px">
                        <View
                          as="p"
                          flex="0 110px"
                          fontSize="12px"
                          textAlign="center"
                          padding="2px 4px"
                          margin="0"
                          color="#6C7278"
                          backgroundColor="#EDF0F8"
                        >
                          最終使用場所
                        </View>
                        <View
                          as="p"
                          flex="1"
                          fontSize="12px"
                          margin="0"
                          color="#6C7278"
                        >
                          {item.last_use_actual_use_location}
                        </View>
                      </Flex>
                      <Flex gap="10px">
                        <View
                          as="p"
                          flex="0 110px"
                          fontSize="12px"
                          textAlign="center"
                          padding="2px 4px"
                          margin="0"
                          color="#6C7278"
                          backgroundColor="#EDF0F8"
                        >
                          使用業者
                        </View>
                        <View
                          as="p"
                          flex="1"
                          fontSize="12px"
                          margin="0"
                          color="#6C7278"
                        >
                          {item.condition === "使用中"
                            ? item.last_use_actual_company_name
                            : "-"}
                        </View>
                      </Flex>
                      <Flex gap="10px">
                        <View
                          as="p"
                          flex="0 110px"
                          fontSize="12px"
                          textAlign="center"
                          padding="2px 4px"
                          margin="0"
                          color="#6C7278"
                          backgroundColor="#EDF0F8"
                        >
                          レンタル会社
                        </View>
                        <View
                          as="p"
                          flex="1"
                          fontSize="12px"
                          margin="0"
                          color="#6C7278"
                        >
                          {item.rentalCompany}
                        </View>
                      </Flex>
                      <Flex gap="10px">
                        <View
                          as="p"
                          flex="0 110px"
                          fontSize="12px"
                          textAlign="center"
                          padding="2px 4px"
                          margin="0"
                          color="#6C7278"
                          backgroundColor="#EDF0F8"
                        >
                          返却予定日
                        </View>
                        <View
                          as="p"
                          flex="1"
                          fontSize="12px"
                          margin="0"
                          color="#6C7278"
                        >
                          {item.returnDate}
                        </View>
                      </Flex>
                    </Flex>
                    <Flex
                      gap="12px"
                      justifyContent="space-between"
                      marginTop="10px"
                      alignItems="center"
                    >
                      <View
                        as="p"
                        flex="1"
                        fontSize="12px"
                        margin="0"
                        color="#6C7278"
                      >
                        {/*最終更新日 : {item.lastDate}*/}
                      </View>
                      <Flex flex="0 96px">
                        <View
                          as="span"
                          backgroundColor={getBadgeColor(item.condition).bg}
                          color={getBadgeColor(item.condition).color}
                          display="block"
                          fontSize="12px"
                          fontWeight="700"
                          padding="5px 0"
                          borderRadius="6px"
                          height="27px"
                          width="100%"
                          textAlign="center"
                        >
                          {item.condition}
                        </View>
                      </Flex>
                    </Flex>
                  </View>
                );
              })}
            </Flex>
          </View>
          <Flex
            justifyContent="center"
            alignItems="center"
            marginTop="20px"
            fontWeight={400}
            fontSize={12}
            letterSpacing={0.4}
            className="hide-from-print"
            direction="column"
          >
            <View>
              {totalCount +
                "件中" +
                indexOfFirstUser +
                "-" +
                indexOfLastUser +
                "件"}
            </View>
            <IconsProvider
              icons={{
                pagination: {
                  next: <ArrowRightIcon />,
                  previous: <ArrowLeftIcon />,
                },
              }}
            >
              <Pagination
                currentPage={currentPageIndex}
                totalPages={totalPages}
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
                // @ts-ignore
                onChange={handleOnChange}
                className={`custom-pagination ${
                  currentPageIndex === totalPages ? "disabled" : ""
                }`}
              />
            </IconsProvider>
          </Flex>
        </View>
      )}
      {fetchError && (
        <Text className="error-fetch-message" style={{color:"black"}}>
          認証が切断されました。画面をリロードいただくか、再ログインをお願いします。
          改善しない場合はシステム管理者にお問い合わせください。
        </Text>
      )}
      <View className="company-action-modal">
        <CommonModal
          isOpen={isActionsModalOpen}
          onClose={() => closeActions()}
          textHeading=""
          textOperation=""
        >
          {isMobile ? (
            <View
              as="ul"
              width="100%"
              style={{ listStyleType: "none", padding: "0" }}
            >
              {(company?.admin_flag === true ||
                (settings &&
                  settings[siteIdSelected]?.page_control?.includes(
                    "equipment_edit"
                  ))) && (
                <View
                  as="li"
                  width="100%"
                  fontSize="16px"
                  marginBottom="20px"
                  paddingBottom="20px"
                  style={{ borderBottom: "1px solid #D9DBDEAD" }}
                >
                  <View onClick={() => handleEdit()}>編集</View>
                </View>
              )}
              {(company?.admin_flag === true ||
                (settings &&
                  settings[siteIdSelected]?.page_control?.includes(
                    "equipment_delete"
                  ))) && (
                <View as="li" width="100%" fontSize="16px">
                  <View onClick={() => setIsDeleteModalOpen(true)}>削除</View>
                </View>
              )}
            </View>
          ) : (
            <>
              {(company?.admin_flag === true ||
                (settings &&
                  settings[siteIdSelected]?.page_control?.includes(
                    "equipment_edit"
                  ))) && (
                <CustomButton
                  {...defaultButtonProps}
                  width="full"
                  text="編集"
                  type="primary"
                  iconName="pen"
                  iconPosition="left"
                  size="large"
                  click={() => handleEdit()}
                />
              )}
              {(company?.admin_flag === true ||
                (settings &&
                  settings[siteIdSelected]?.page_control?.includes(
                    "equipment_delete"
                  ))) && (
                <CustomButton
                  {...defaultButtonProps}
                  width="full"
                  text="削除"
                  type="default"
                  iconName="trash"
                  iconPosition="left"
                  size="large"
                  click={() => setIsDeleteModalOpen(true)}
                />
              )}
            </>
          )}
        </CommonModal>
      </View>
      <CommonModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        textHeading={`機材「${nameSelected}」の情報を削除します。よろしいですか？`}
        textOperation="この操作は取り消せません。本当に削除しますか？"
      >
        <CustomButton
          {...defaultButtonProps}
          text="キャンセル"
          type="default"
          iconName="close"
          iconPosition="left"
          size="large"
          click={() => setIsDeleteModalOpen(false)}
        />
        <CustomButton
          {...defaultButtonProps}
          text="機材情報を削除"
          type="warning"
          iconName={disableButton ? "loader" : ""}
          iconPosition="right"
          disabled={disableButton}
          size="large"
          click={() => handleDelete()}
        />
      </CommonModal>
    </PageContent>
  );
}
